
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&display=swap');
@import url("https://fonts.googleapis.com/css?family=Ubuntu+Condensed&display=swap");

body {
    margin: 0;
    font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

img{
-webkit-backface-visibility: hidden;
}

